<template>
    <div class="container">
        <header>
            <router-link :to="{ name: 'index',query:{
              memberId: this.$route.query.memberId,
              merchantCode: this.$route.query.merchantCode,
              merchantAccessToken: this.$route.query.merchantAccessToken,
              uniappLogin: this.$route.query.uniappLogin,
              uniappLoginYy: this.$route.query.uniappLoginYy
                }}" custom v-slot="{ href }">
                <a :href="href">
                    <i class="icon-leftarrow"></i>
                    返回
                </a>
            </router-link>
        </header>
        <img src="@/assets/img/reservation-complete.png" alt="" />
        <h1>支付成功</h1>
        <span>我們已收到預約費用</span>
        <span>請稍後，醫生將會儘快確認時間</span>
        <router-link
            :to="{
                name: 'reservationDetail',
                params: { id: $route.params.id },
            }"
            custom
            v-slot="{ navigate }"
        >
            <button class="rounded-primary" @click="navigate">詳情</button>
        </router-link>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
    width: 100vw;
    height: 100vh;
    background-color: $white;
    padding: var(--safe-top) 0 var(--safe-bottom);
    @include flexc-start-center;
    header {
        width: 100%;
        padding: 32px 16px;
        a {
            color: $green-300;
            font-weight: $semi-bold;
            font-size: 24px;
            text-decoration: none;
        }
    }
    img {
        width: 80%;
        align-self: center;
    }
    h1 {
        font-size: 23px;
        color: $black;
        font-weight: $semi-bold;
    }
    button {
        margin-top: 128px;
        width: 60%;
    }
}
</style>