var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('header',[_c('router-link',{attrs:{"to":{ name: 'index',query:{
          memberId: this.$route.query.memberId,
          merchantCode: this.$route.query.merchantCode,
          merchantAccessToken: this.$route.query.merchantAccessToken,
          uniappLogin: this.$route.query.uniappLogin,
          uniappLoginYy: this.$route.query.uniappLoginYy
            }},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
return [_c('a',{attrs:{"href":href}},[_c('i',{staticClass:"icon-leftarrow"}),_vm._v(" 返回 ")])]}}])})],1),_c('img',{attrs:{"src":require("@/assets/img/reservation-complete.png"),"alt":""}}),_c('h1',[_vm._v("支付成功")]),_c('span',[_vm._v("我們已收到預約費用")]),_c('span',[_vm._v("請稍後，醫生將會儘快確認時間")]),_c('router-link',{attrs:{"to":{
            name: 'reservationDetail',
            params: { id: _vm.$route.params.id },
        },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('button',{staticClass:"rounded-primary",on:{"click":navigate}},[_vm._v("詳情")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }